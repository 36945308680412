.dataChart .modebar {
    left: 32%;
}
 .dataChart .js-plotly-plot {
     width: 100%;
     height: 220px;
 }

 .dataChart .xy{
     height: 220px;
 }

.dataChartNotFound {
    height: 420px;
}



